<template>
  <div>
    <v-main class="center-content clustering_content" style="margin-top: 150px; padding-left: 5rem !important;">
      <div v-if="clusteringKeywordsSemantic">
        <div v-if="contextAccountId != 'geo-dashboard'
          ">
          <Barcontext :contextName="contextName" :context-keywords="arrayKeywords"
            @close="closeSocialFilter(false)" @closeMultiSelect="closeDriverFilter(false)"
            @changeSocialFilter="changeSocialFilter" @dataLoading="setLoader" />
        </div>
        <div v-if="contextAccountId == 'geo-dashboard'
          ">
          <BarContextBoards :contextName="contextName" :context-keywords="arrayKeywords"
            @updateFbDrivers="updateFbDrivers" @updateTwDrivers="updateTwDrivers" @updateIgDrivers="updateIgDrivers"
            @updateLkDrivers="updateLkDrivers" @updateNwsDrivers="updateNwsDrivers" @close="closeSocialFilter(true)"
            @closeMultiSelect="closeDriverFilter(true)" @dataLoading="setLoader" />
        </div>
      </div>
      <div class="component-content__tabs">
        <v-tabs centered slider-color="#00A5FF" v-model="model" background-color="#f3f4f7" height="51" grow
          class="component-content__tabs-menu">
          <v-tab>
            <span class="font15pt titleColor font-weight-bold text-capitalize mr-1">Semantic Analysis</span>
            <span><v-icon size="16" color="#1da1f2" class="mr-1"
                v-if="socialFilter.some(d => d === 'tw') || socialFilter == 'all'">fab fa-twitter</v-icon></span>
            <span><v-icon size="16" color="#1877f2" class="mr-1"
                v-if="socialFilter.some(d => d === 'fb') || socialFilter == 'all'">fab
                fa-facebook</v-icon></span>
            <span><v-icon size="16" color="#ff69a1" class="mr-1"
                v-if="socialFilter.some(d => d === 'ig') || socialFilter == 'all'">fab
                fa-instagram</v-icon></span>
            <span><v-icon size="16" color="#0a66c2" class="mr-1"
                v-if="socialFilter.some(d => d === 'lk') || socialFilter == 'all'">fab
                fa-linkedin</v-icon></span>
            <span><v-icon size="16" color="#444444" class="mr-1"
                v-if="socialFilter.some(d => d === 'nws') || socialFilter == 'all'">fa-solid fa-newspaper</v-icon></span>
          </v-tab>
          <v-tab>
            <span class="font15pt titleColor font-weight-bold text-capitalize mr-1">influencer</span>
            <span><v-icon size="16" color="#1da1f2">fab fa-twitter</v-icon></span>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="model" class="component-content__tabs__info pb-0" style="padding: 50px" touchless>
          <v-tab-item transition="fade-transition" reverse-transition="fade-transition">
            <v-main class="tab-padding" v-if="!refreshSemantic">
              <v-row>
                <v-col>
                  <GraphSemantic @changeSocialFilter="changeSocialFilter"
                    :words="words" />
                </v-col>
              </v-row>
              <v-row class="mt-5 mb-5">
                <v-col>
                  <TextAnalyticsDetail 
                    :wordsKeySamples="wordsKeySamples"
                    :hashtagsSamples="hashtagsSamples"
                    :mentionsSamples="mentionsSamples" />
                </v-col>
              </v-row>
            </v-main>
          </v-tab-item>
          <v-tab-item transition="fade-transition" reverse-transition="fade-transition">
            <v-main class="tab-padding" v-if="clusteringKeywordsInfluencer">
              <v-row>
                <v-col>
                  <GraphInfluencer :influencers="influencers" />
                </v-col>
              </v-row>
              <v-row class="mt-5 mb-5">
                <v-col>
                  <TopInfluencer :influencers="influencers" />
                </v-col>
              </v-row>
            </v-main>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-main>
  </div>
</template>

<script>
import Barcontext from "@/components/base/barcontext";
import BarContextBoards from "@/components/base/barContextBoards";

import GraphSemantic from "@/components/clustering-analysis/semanticAnalysis/graphSemantic.vue";
import TextAnalyticsDetail from "@/components/clustering-analysis/semanticAnalysis/textAnalyticsDetail.vue";

import GraphInfluencer from "@/components/clustering-analysis/influencers/graphInfluencer.vue";
import TopInfluencer from "@/components/clustering-analysis/influencers/topInfluencer.vue";

import { util } from "@isc/styleguide";

export default {
  data() {
    return {
      model: "tabs-overview",
      filterData: {
        context_id: null,
        start: null,
        end: null,
        tw_active_drivers: null,
        fb_active_drivers: null,
        ig_active_drivers: null,
        lk_active_drivers: null,
        nws_active_drivers: null,
        onlyTwitter: false,
      },
      arrayKeywords: [],
      clusteringKeywordsSemantic: null,
      clusteringKeywordsInfluencer: null,
      refreshSemantic: false,
      refreshInfluencer: false,
      socialFilter: ["tw", "fb", "ig", "lk", "nws"],
      wordsKeySamples: [],
      hashtagsSamples: [],
      mentionsSamples: [],
      words: []

    };
  },
  components: {
    Barcontext,
    BarContextBoards,
    GraphSemantic,
    TextAnalyticsDetail,
    GraphInfluencer,
    TopInfluencer,
  },
  computed: {
    contextName() {
      return this.clusteringKeywordsSemantic.socialNetworksData.context_name || clusteringKeywordsSemantic.newspapersData.context_name
    },
    contextAccountId() {
      return this.clusteringKeywordsSemantic.socialNetworksData.context_account_id || clusteringKeywordsSemantic.newspapersData.context_account_id
    },
    influencers() {
      return [...this.clusteringKeywordsInfluencer.socialNetworksData.influencers, ...this.clusteringKeywordsInfluencer.newspapersData.influencers]
    }
  },
  methods: {
    getMainData(onlyTwitter = false) {
      let tw_active_drivers = this.filterData.tw_active_drivers;
      if (onlyTwitter) {
        if (!tw_active_drivers) {
          tw_active_drivers = this.arrayKeywords
            .filter((keyword) => keyword.type == "tw")
            .map((driver) => driver.id);
        }
      }
      const [start, end] = this.$store.getters["global/dateRange"];
      const startDate = util.convertTimestampToDateFormat(start, "DD-MM-YYYY");
      const endDate = util.convertTimestampToDateFormat(end, "DD-MM-YYYY");
      return {
        context_id: this.$route.params.context,
        start: startDate, //"01-07-2021",
        end: endDate, //"15-07-2021",
        tw_active_drivers: tw_active_drivers,
        fb_active_drivers: onlyTwitter
          ? null
          : this.filterData.fb_active_drivers,
        ig_active_drivers: onlyTwitter
          ? null
          : this.filterData.ig_active_drivers,
        lk_active_drivers: onlyTwitter
          ? null
          : this.filterData.lk_active_drivers,
        nws_active_drivers: onlyTwitter
          ? null
          : this.filterData.nws_active_drivers,
        onlyTwitter: onlyTwitter ? true : false,
      };
    },
    setMainData() {
      this.filterData = this.getMainData(false);
    },
    emit(eventName, value) {
      return new Promise((resolve, reject) => {
        this.$emit(eventName, value);
        this.$nextTick(resolve);
      });
    },
    async setLoader(val) {
      await this.emit("setLoader", val);
    },
    async dataForSemantic() {
      this.refreshSemantic = true;
      const { data } = await this.$store.dispatch(
        "clusteringAnalysis/clusteringKeywords",
        this.filterData
      );

      if (data == null) {
        alert("No hay drivers activos");
        this.$router.push({ name: "home" });
      } else {
        this.clusteringKeywordsSemantic = data.clusteringKeywords;
        const { socialNetworksData, newspapersData } = data.clusteringKeywords
        
        this.wordsKeySamples = [...(socialNetworksData.words_key || []), ...(newspapersData.words_key || [])]
        this.hashtagsSamples = [...(socialNetworksData.hashtags || []), ...(newspapersData.hashtags || [])]
        this.mentionsSamples = [...(socialNetworksData.mentions || []), ...(newspapersData.mentions || [])]
        this.words = [...(socialNetworksData.words || []), ...(newspapersData.words || [])]

        this.arrayKeywords = [];
        this.clusteringKeywordsSemantic.socialNetworksData.keywords.forEach((keywordItem) => {
          keywordItem.name = keywordItem.id;
          this.arrayKeywords.push(keywordItem);
        });
      }
      this.refreshSemantic = false;
    },
    async dataForInfluencer() {
      this.refreshInfluencer = true;
      const filterData = this.getMainData(true);
      const { data } = await this.$store.dispatch(
        "clusteringAnalysis/clusteringKeywords",
        filterData
      );
      if (data == null) {
        alert("No hay drivers activos");
        this.$router.push({ name: "home" });
      } else {
        this.clusteringKeywordsInfluencer = data.clusteringKeywords;
        //this.arrayKeywords = [];
        /* this.clusteringKeywordsInfluencer.keywords.forEach((keywordItem) => {
          keywordItem.name = keywordItem.id;
          this.arrayKeywords.push(keywordItem);
        }); */
      }
      this.refreshInfluencer = false;
    },
    async loadData() {
      /* this.clusteringKeywordsSemantic = null;
      this.clusteringKeywordsInfluencer = null; */
      await this.dataForSemantic();
      await this.dataForInfluencer();
    },
    async refreshData() {
      await this.setLoader(true);
      await this.loadData();
      await this.setLoader(false);
    },
    async updateTwDrivers(tw_ids) {
      this.filterData.tw_active_drivers = tw_ids;
    },
    async updateFbDrivers(fb_ids) {
      this.filterData.fb_active_drivers = fb_ids;
    },
    async updateIgDrivers(ig_ids) {
      this.filterData.ig_active_drivers = ig_ids;
    },
    async updateLkDrivers(lk_ids) {
      this.filterData.lk_active_drivers = lk_ids;
    },
    async updateNwsDrivers(nws_ids) {
      this.filterData.nws_active_drivers = nws_ids;
    },
    closeSocialFilter(geo = false) {
      if (!geo) {
        this.refreshData();
      } else {
        this.refreshData();
      }
    },
    closeDriverFilter(geo = false) {
      if (!geo) {
        this.refreshData();
      } else {
        this.refreshData();
      }
    },
    changeSocialFilter(val) {
      this.socialFilter = val;
    },
  },
  async mounted() {
    this.setMainData();
    await this.loadData();
    await this.setLoader(false);
  },
  watch: {
    "$store.state.global.dateRange": {
      handler: async function () {
        this.setMainData();
        await this.refreshData();
      },
    },
    /* arrayKeywords: {
      immediate: true,
      handler: function () {
        console.log(this.arrayKeywords);
      },
    }, */
  },
};
</script>

<style>
.v-main__clustering {
  padding: 70px 0px 50px 50px !important;
}

.clustering_content {
  margin-top: 150px;
  width: 75% !important;
}

@media screen and (min-width: 540px) and (max-width: 1024px) {
  .clustering_content {
    width: 90% !important;
  }
}
</style>