<template>
  <v-main>
    <v-row>
      <v-col class="d-flex align-center">
        <h1 class="font15pt font-weight-bold mr-2">
          Influencer Identification
        </h1>
        <tooltipComponent :tooltipText="tooltip" />
      </v-col>
    </v-row>
    <v-row>
      <div class="graph-secction col">
        <div class="graph-secction__base pa-3 bgColorMenu position-relative">
          <div
            v-if="modal.id"
            class="zIndex_1 position-absolute white elevation-1"
            :style="{ left: modal.x + 'px', top: modal.y + 'px' }"
          >
            <div class="close position-absolute zIndex_1" @click="closeModal()">
              <v-icon size="20" color="#2c3852"> fas fa-times </v-icon>
            </div>
            <v-card rounded="0" width="380">
              <div
                v-if="modal.type === 'mention'"
                class="
                  top_modal
                  d-flex
                  bgGrapg
                  align-center
                  pl-5
                  pt-4
                  pr-5
                  pb-4
                "
              >
                <v-avatar size="40">
                  <img
                    alt="user"
                    :src="modal.image"
                    @error="
                      $event.target.src =
                        'https://listen-images.s3.us-east-2.amazonaws.com/user-image.png'
                    "
                  />
                </v-avatar>
                <span class="white--text font15pt font-weight-bold">
                  {{ modal.label }}
                </span>
              </div>
              <div class="body_modal pl-5 pt-4 pr-5 pb-4">
                <div v-if="modal.type === 'influencer'">
                  <div class="d-flex">
                    <div class="d-flex">
                      <v-avatar size="40">
                        <img
                          alt="user"
                          :src="modal.image"
                          @error="
                            $event.target.src =
                              'https://listen-images.s3.us-east-2.amazonaws.com/user-image.png'
                          "
                        />
                      </v-avatar>
                    </div>
                    <div class="col">
                      <div class="d-flex flex-column">
                        <div class="name">
                          <span class="titleColor font15pt font-weight-bold">
                            {{ modal.label }}
                          </span>
                          <span class="linkColor font12pt">
                            @{{ modal.label }}
                          </span>
                        </div>
                        <span class="font10pt leyendColor">{{
                          modal.time
                        }}</span>
                        <div class="mt-1 is_rt" v-if="modal.is_ret === 'true'">
                          <span class="pl-1 font10pt leyendColor"
                            >retweeted of </span
                          ><span class="font10pt ColorMale">{{
                            modal.ret_name
                          }}</span>
                        </div>
                        <p class="font12pt parColor mt-2">
                          {{ modal.text }}
                        </p>
                        <img :src="modal.post_img" class="col pa-0" alt="" />
                        <div class="d-flex mt-3">
                          <div class="mr-4 d-none">
                            <v-icon size="14" color="#a2acc4">
                              far fa-comments
                            </v-icon>
                            <span class="ml-1 font10pt leyendColor">2</span>
                          </div>
                          <div>
                            <v-icon size="14" color="#a2acc4">
                              far fa-heart
                            </v-icon>
                            <span class="ml-1 font10pt leyendColor">{{
                              modal.post_like
                            }}</span>
                          </div>
                          <div class="ml-4">
                            <v-icon size="14" color="#a2acc4">
                              fas fa-retweet
                            </v-icon>
                            <span class="ml-1 font10pt leyendColor">{{
                              modal.post_retweet
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="modal.type === 'mention'"
                  class="d-flex flex-column"
                  :class="{ ment_: modal.post.length > 1 }"
                >
                  <div
                    class="d-flex"
                    v-for="(item, index) in modal.post"
                    v-bind:key="index"
                  >
                    <div class="d-flex">
                      <v-avatar size="40">
                        <img
                          alt="user"
                          :src="item.avatar"
                          @error="
                            $event.target.src =
                              'https://listen-images.s3.us-east-2.amazonaws.com/user-image.png'
                          "
                        />
                      </v-avatar>
                    </div>
                    <div class="col">
                      <div class="d-flex flex-column">
                        <div class="name">
                          <span class="titleColor font15pt font-weight-bold">
                            {{ item.label }}
                          </span>
                          <span class="linkColor font12pt">
                            @{{ item.label }}
                          </span>
                        </div>
                        <span class="font10pt leyendColor"
                          >{{ item.time }} hours
                        </span>
                        <div class="mt-1 is_rt" v-if="item.is_ret === 'true'">
                          <span class="pl-1 font10pt leyendColor"
                            >retweeted of </span
                          ><span class="font10pt ColorMale">{{
                            item.ret_name
                          }}</span>
                        </div>
                        <p class="font12pt parColor mt-2">
                          {{ item.text }}
                        </p>
                        <img :src="item.post_img" class="col pa-0" alt="" />
                        <div class="d-flex mt-3">
                          <div class="mr-4 d-none">
                            <v-icon size="14" color="#a2acc4">
                              far fa-comments
                            </v-icon>
                            <span class="ml-1 font10pt leyendColor">2</span>
                          </div>
                          <div>
                            <v-icon size="14" color="#a2acc4">
                              far fa-heart
                            </v-icon>
                            <span class="ml-1 font10pt leyendColor">{{
                              item.post_like
                            }}</span>
                          </div>
                          <div class="ml-4">
                            <v-icon size="14" color="#a2acc4">
                              fas fa-retweet
                            </v-icon>
                            <span class="ml-1 font10pt leyendColor">{{
                              item.post_retweet
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="footer_modal pl-5 pt-4 pr-5 d-flex pb-4 bgColorBase">
                <div class="ico_left">
                  <v-icon size="40" color="#1da1f2">fab fa-twitter</v-icon>
                </div>
                <div class="col-8 pt-0">
                  <div
                    v-if="modal.type === 'influencer'"
                    class="col pa-0 mb-3 d-flex justify-space-between"
                  >
                    <span class="parColor font12pt"> Retweet: </span>
                    <span class="parColor font12pt font-weight-bold">
                      {{ modal.retweet }}
                    </span>
                  </div>
                  <div
                    v-if="modal.type === 'influencer'"
                    class="col pa-0 mb-3 d-flex justify-space-between"
                  >
                    <span class="parColor font12pt"> Community: </span>
                    <span class="parColor font12pt font-weight-bold">
                      {{ modal.community }}
                    </span>
                  </div>
                  <div
                    v-if="modal.type === 'mention' && modal.more_than_one_influ"
                    class="col pa-0 mb-3 d-flex justify-space-between"
                  >
                    <span class="parColor font12pt"> User with more interactions: </span>
                    <span class="parColor font12pt font-weight-bold">
                      {{ modal.influ_more_interactions }}
                    </span>
                  </div>
                  <div class="col pa-0 mb-3 d-flex justify-space-between">
                    <span class="parColor font12pt"> Interactions: </span>
                    <span class="parColor font12pt font-weight-bold">
                      {{ modal.interactions }}
                    </span>
                  </div>
                  <div class="col pa-0 mb-3 d-flex justify-space-between">
                    <span class="parColor font12pt"> Sentiment: </span>
                    <span class="parColor font12pt font-weight-bold">
                      <v-icon
                        v-if="modal.sentiment == 'neutral'"
                        small
                        color="#828a96"
                      >
                        far fa-hand-point-right
                      </v-icon>
                      <v-icon
                        v-if="modal.sentiment == 'pos'"
                        small
                        color="#9fd895"
                      >
                        far fa-thumbs-up
                      </v-icon>
                      <v-icon
                        v-if="modal.sentiment == 'neg'"
                        small
                        color="#ff7e7e"
                      >
                        far fa-thumbs-down
                      </v-icon>
                    </span>
                  </div>
                  <div
                    v-if="modal.type === 'influencer'"
                    class="col pa-0 d-flex justify-space-between"
                  >
                    <span class="parColor font12pt"> Score influencer: </span>
                    <span class="parColor font12pt font-weight-bold">
                      {{ modal.score }}%
                    </span>
                  </div>
                </div>
              </div>
            </v-card>
          </div>
          <network
            ref="network"
            :nodes="nodes"
            :edges="edges"
            :options="options"
            @select-node="networkEvent('selectNode')"
          />
        </div>
        <div
          class="
            graph-secction__info
            bgClusteringInfo
            pa-5
            d-flex
            justify-space-between
          "
        >
          <div class="graph-secction__info__title pl-5 d-flex align-center">
            <h1 class="colorPrincipal font15pt font-weight-bold">
              Conversion Cluster base on:
            </h1>
          </div>
          <div class="graph-secction__info__icons pr-5 d-flex align-end">
            <div
              class="
                graph-secction__info__icons__item
                d-flex
                flex-column
                align-center
                mr-5
                position-relative
              "
            >
              <div
                class="
                  graph-secction__info__icons__item__top
                  mb-1
                  d-flex
                  align-center
                "
              >
                <span
                  class="rounded-circle-complete d-inline-block d-flex justify-center"
                  style="width: 20px; height: 20px"
                >
              </span>
                <span
                  class="rounded-circle d-inline-block d-flex justify-center ml-1"
                  style="width: 20px; height: 20px"
                >
                <v-icon size="12" color="#00A5FF" style="padding-bottom: 2px;">@</v-icon>
              </span>
              </div>
              <div class="graph-secction__info__icons__item__name leyendColor">
                <span>Circles</span>
              </div>
              <div class="float-content position-relative">
                <div
                  class="
                    white
                    elevation-1
                    position-absolute
                    pa-3
                    pt-5
                    pb-5
                    float-info
                  "
                >
                  <div class="arrow-top position-absolute">
                    <v-icon size="30" color="white"> fa fa-caret-up </v-icon>
                  </div>
                  <div class="mb-2 font12pt parColor mb-1">
                    The width of the circle indicates who is the most
                    influential user.
                  </div>
                  <div class="font12pt parColor font-weight-bold mb-1">
                    Nodes:
                  </div>
                  <div class="font11pt colorPrincipal font-weight-bold pl-1">
                    Main Node:
                  </div>
                  <div class="font11pt parColor pl-1">
                    Influencer (content generator) with more interactions
                    highlighted with white print.
                  </div>
                  <div
                    class="font11pt colorPrincipal font-weight-bold pl-1 mt-1"
                  >
                    Sub Node:
                  </div>
                  <div class="font11pt parColor pl-1">
                    Mentions that generated the most interactions, highlighted
                    in blue.
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                graph-secction__info__icons__item
                d-flex
                flex-column
                align-center
                position-relative
              "
            >
              <div
                class="
                  graph-secction__info__icons__item__top
                  mb-1
                  d-flex
                  align-center
                "
              >
                <span
                  ><v-icon size="12" color="#9fd895"
                    >fas fa-long-arrow-alt-up</v-icon
                  ></span
                >
                <span class="pl-1 pr-1"
                  ><v-icon size="12" color="#ff7e7e"
                    >fas fa-long-arrow-alt-up</v-icon
                  ></span
                >
                <span
                  ><v-icon size="12" color="#828a96"
                    >fas fa-long-arrow-alt-up</v-icon
                  ></span
                >
              </div>
              <div class="graph-secction__info__icons__item__name leyendColor">
                <span>Color arrows</span>
              </div>
              <div class="float-content position-relative">
                <div
                  class="
                    white
                    elevation-1
                    position-absolute
                    pa-3
                    pt-5
                    pb-5
                    float-info
                  "
                >
                  <div class="arrow-top position-absolute">
                    <v-icon size="30" color="white"> fa fa-caret-up </v-icon>
                  </div>
                  <div class="mb-1 font12pt parColor">
                    Indicates the sentiment of influencer with the community.
                  </div>
                  <div class="font12pt parColor font-weight-bold">Arrows:</div>
                  <div
                    class="font11pt colorPrincipal font-weight-bold pl-1 mt-1"
                  >
                    Color Arrow:
                  </div>
                  <div class="font11pt parColor mb-1 pl-1">
                    Sentiment generated when relating the Nodes.
                  </div>
                  <div class="font11pt parColor pl-1">
                    <span
                      class="d-inline-block rounded-circle bgColorpositive mr-1"
                      style="width: 10px; height: 10px"
                    ></span
                    ><span>Positive sentiment.</span>
                  </div>
                  <div class="font11pt parColor pl-1">
                    <span
                      class="d-inline-block rounded-circle bgColornegative mr-1"
                      style="width: 10px; height: 10px"
                    ></span
                    ><span>Negative sentiment.</span>
                  </div>
                  <div class="font11pt parColor pl-1">
                    <span
                      class="d-inline-block rounded-circle bgColorneutral mr-1"
                      style="width: 10px; height: 10px"
                    ></span
                    ><span>Neutral sentiment.</span>
                  </div>
                  <div
                    class="font11pt colorPrincipal font-weight-bold pl-1 mt-1"
                  >
                    Thickness arrow:
                  </div>
                  <div class="font11pt parColor mb-1 pl-1">
                    Indicates that there are more interactions between the
                    nodes.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-row>
  </v-main>
</template>

<script>
import { util } from "@isc/styleguide";
import tooltipComponent from "@/components/base/tooltip.vue";
//let img = require('@/src/assets/ico.png');

export default {
  name: "GraphInfluencer",
  components: { tooltipComponent },
  props: {
    influencers: Array,
  },
  data() {
    return {
      tooltip: "Distribution generated around the driver by words.",
      nodes: [],
      nodeList: [],
      edges: [],
      modal: {},
      options: {
        autoResize: true,
        height: "600px",
        nodes: {
          scaling: {
            label: {
              min: 10,
              max: 20,
            },
            customScalingFunction: function (min, max, total, value) {
              return value / total;
            },
            min: 5,
            max: 50,
          },
          shape: "dot",
          size: 5,
          /* font: {
            color: "#fff",
            multi: true,
            bold: "18px Oxygen #fff",
          }, */
          borderWidth: 0,
        },
        edges: {
          physics: true,
          scaling: {
            min: 5,
            max: 10,
          },
          selectionWidth: 1.8,
        },
      },
      iscColors: {
        pos: "#9fd895",
        neutral: "#828a96",
        neg: "#ff7e7e",
      },
    };
  },
  mounted() {
    //console.log('--->', this.influencers)
    // console.log('----<', util)
  },
  watch: {
    influencers: {
      immediate: true,
      handler: function () {
        if (this.influencers.length > 0) {
          const influencers = [];
          const menciones = [];
          const mencionesNoInfluencers = [];
          const links = [];
          for (const [index, influencerItem] of this.influencers.entries()) {
            const mentionsItem =
              influencerItem["agg_terms_sample_mentions.keyword"];
            const authorPhotoItem =
              influencerItem["agg_terms_author_photo.keyword"];
            const sampleTextItem =
              influencerItem["agg_terms_sample_text.keyword"].buckets[0];
            const sentimentItem =
              influencerItem["agg_terms_sample_sentiment.keyword"];

            const mentionsData = mentionsItem.buckets;
            const image_ = authorPhotoItem.buckets[0].key;
            const favourites = Math.ceil(
              influencerItem.agg_avg_author_favourites_count.value
            );
            const followers = Math.ceil(
              influencerItem.agg_avg_author_followers_count.value
            );
            const post_item_score =
              favourites != 0
                ? Math.round((favourites / followers) * 100) / 100
                : "0.00";
            const time = sampleTextItem
              ? sampleTextItem["agg_terms_sample_created_at"].buckets[0].key
              : 0;
            const post_like = sampleTextItem
              ? sampleTextItem["agg_sum_sample_favorite_count"].value
              : 0;
            const post_retweet = sampleTextItem
              ? sampleTextItem["agg_sum_sample_retweet_count"].value
              : 0;
            const interactions =
              influencerItem["agg_sum_sample_interactions"].value;
            const text = sampleTextItem ? sampleTextItem.key : "No Data Post";
            const sentiment = sentimentItem.buckets[0].key;
            const retweet =
              influencerItem["agg_sum_sample_retweet_count"].value;
            const post_item_is_retweet = sampleTextItem
              ? sampleTextItem["agg_terms_sample_is_rt"].buckets[0]
                ? sampleTextItem["agg_terms_sample_is_rt"].buckets[0]
                    .key_as_string
                : ""
              : "";
            const post_item_retweet_name =
              post_item_is_retweet == "true"
                ? sampleTextItem
                  ? sampleTextItem["agg_terms_author_fullname.keyword"]
                      .buckets[0].key
                  : "-"
                : "";
            const img_post = sampleTextItem
              ? sampleTextItem["agg_terms_sample_picture_url.keyword"].buckets
              : "";
            const img_ = img_post[0] ? img_post[0].key : "";
            const community =
              influencerItem["agg_avg_author_followers_count"].value;

            const influencers_node = {
              id: `${influencerItem.key}`,
              label: influencerItem.key,
              color: "#00a5ff",
              mass: 1,
              image:
                image_ ||
                `https://listen-images.s3.us-east-2.amazonaws.com/user-image.png`,
              size: 40,
              shape: "circularImage",
              inf_key: index,
              type: "influencer",
              score: post_item_score,
              time: `${util.convertTimestampToDate(
                time
              )} - ${util.convertTimestampToTime(time)}`,
              sentiment: sentiment,
              post_like: util.convertNumberTokFormat(post_like),
              interactions: util.convertNumberTokFormat(interactions),
              community: util.convertNumberTokFormat(community),
              image_post: img_,
              retweet: util.convertNumberTokFormat(retweet),
              post_retweet: util.convertNumberTokFormat(post_retweet),
              text: text,
              is_ret: post_item_is_retweet,
              ret_name: post_item_retweet_name,
              font: {
                color: "#fff",
                multi: true,
                bold: "18px Oxygen #fff",
              },
            };

            for (const mention of mentionsData) {
              const ment_data =
                mention["agg_terms_sample_text.keyword"].buckets;
              const ment_interactions =
                mention["agg_sum_sample_interactions"].value;
              const ment_sentiment =
                mention["agg_terms_sample_sentiment.keyword"].buckets[0].key;
              const data_post = ment_data.map((x) => {
                const mention_is_retweet = x["agg_terms_sample_is_rt"]
                  .buckets[0]
                  ? x["agg_terms_sample_is_rt"].buckets[0].key_as_string
                  : "";
                const mention_is_retweet_name =
                  mention_is_retweet === "true"
                    ? x["agg_terms_author_fullname.keyword"].buckets[0]
                      ? x["agg_terms_author_fullname.keyword"].buckets[0].key
                      : ""
                    : "";
                const post_item_mnt_time = x["agg_terms_sample_created_at"]
                  .buckets[0]
                  ? x["agg_terms_sample_created_at"].buckets[0].key
                  : 0;
                const post_ = x.key;
                const img_ = x["agg_terms_sample_picture_url.keyword"]
                  .buckets[0]
                  ? x["agg_terms_sample_picture_url.keyword"].buckets[0].key
                  : "";
                const retweet_ = x["agg_sum_sample_retweet_count"].value;
                const favorite_ = x["agg_sum_sample_favorite_count"].value;
                const items = {
                  label: influencerItem.key,
                  avatar:
                    image_ ||
                    `https://listen-images.s3.us-east-2.amazonaws.com/user-image.png`,
                  text: post_,
                  image_post: img_,
                  post_retweet: util.convertNumberTokFormat(retweet_),
                  post_like: util.convertNumberTokFormat(favorite_),
                  time: `${util.convertTimestampToDate(
                    post_item_mnt_time
                  )} - ${util.convertTimestampToTime(post_item_mnt_time)}`,
                  is_ret: mention_is_retweet,
                  ret_name: mention_is_retweet_name,
                  interactions: (retweet_ ? retweet_ : 0) + (favorite_ ? favorite_ : 0)
                };
                return items;
              });
              if (menciones.some((data) => data.id == mention.key)) {
                const foundIndex = menciones.findIndex(
                  (x) => x.id == mention.key
                );
                menciones[foundIndex].post = [
                  ...menciones[foundIndex].post,
                  ...data_post,
                ];
                menciones[foundIndex].interactions = menciones[foundIndex].interactions + ment_interactions
                menciones[foundIndex].influ_more_interactions = menciones[foundIndex].post.sort(((a, b) => b.interactions - a.interactions))[0].label;

                const users_unique_count = [...new Set(menciones[foundIndex].post.map(data => data.label))].length;
                if(users_unique_count > 1){
                  menciones[foundIndex].more_than_one_influ = true;
                }else{
                  menciones[foundIndex].more_than_one_influ = false;
                }
              }
              const influ_more_interactions = data_post.sort(((a, b) => b.interactions - a.interactions))[0];
              const mentions_node = {
                id: `${mention.key}`,
                label: mention.key,
                color: "#00a5ff",
                image:
                  "https://listen-images.s3.us-east-2.amazonaws.com/arroba.png",
                size: 20,
                mass: 2,
                shape: "circularImage",
                inf_key: index,
                inf_id: influencerItem.key,
                type: "mention",
                font: {
                  color: "#00a5ff",
                  multi: true,
                  bold: "18px Oxygen #00a5ff",
                },
                scaling: {
                  min: 105,
                  max: 110,
                },
                more_than_one_influ: false,
                influ_more_interactions: influ_more_interactions.label,
                interactions: ment_interactions/* util.convertNumberTokFormat(ment_interactions) */,
                sentiment: ment_sentiment,
                post: data_post,
              };
              menciones.push(mentions_node);
            }
            influencers.push(influencers_node);
          }

          for (const [index, mencion] of menciones.entries()) {
            mencion.interactions = util.convertNumberTokFormat(mencion.interactions)
            const influencerExists = influencers.find(
              (influ) => influ.id == mencion.id
            );
            if (influencerExists) {
              // si la mencion ya es un influencer
              if (influencerExists.inf_key == mencion.inf_key) {
                // el influencer se menciona a sí mismo
                const auto_mention = {
                  from: influencerExists.id,
                  to: influencerExists.id,
                  width: 2,
                  scaling: {
                    min: 105,
                    max: 110,
                  },
                  color: {
                    color: this.iscColors[influencerExists.sentiment],
                    highlight: this.iscColors[influencerExists.sentiment],
                    hover: this.iscColors[influencerExists.sentiment],
                    inherit: "from",
                    opacity: 1.0,
                  },
                  arrows: "to",
                };
                menciones.splice(index, 1); // eliminar automention
                links.push(auto_mention);
              } else {
                // el influencer menciona a otro influencer
                const link = {
                  from: mencion.inf_id,
                  to: influencerExists.id,
                  width: 2,
                  scaling: {
                    min: 105,
                    max: 110,
                  },
                  color: {
                    color: this.iscColors[influencerExists.sentiment],
                    highlight: this.iscColors[influencerExists.sentiment],
                    hover: this.iscColors[influencerExists.sentiment],
                    inherit: "from",
                    opacity: 1.0,
                  },
                  arrows: "to",
                };
                menciones.splice(index, 1);
                links.push(link);
              }
            } else {
              //la mencion no es un influencer
              //crear nodo
              //verificar si nodo existe, en ese caso, crear link
              const mentionExists = mencionesNoInfluencers.some(
                (ment) => ment.id == mencion.id
              );
              if (!mentionExists) {
                //Si la mencion no existe
                //añade el nodo
                mencionesNoInfluencers.push(mencion);
                //crea el link
                const link = {
                  from: mencion.inf_id,
                  to: mencion.id,
                  width: 2,
                  arrowStrikethrough: true,
                  color: {
                    color: this.iscColors[mencion.sentiment],
                    highlight: this.iscColors[mencion.sentiment],
                    hover: this.iscColors[mencion.sentiment],
                    inherit: "from",
                    opacity: 1.0,
                  },
                  arrows: "to",
                };
                links.push(link);
              } else {
                //mencion ya existe, crear link
                const link = {
                  from: mencion.inf_id,
                  to: mencion.id,
                  width: 2,
                  arrowStrikethrough: true,
                  color: {
                    color: this.iscColors[mencion.sentiment],
                    highlight: this.iscColors[mencion.sentiment],
                    hover: this.iscColors[mencion.sentiment],
                    inherit: "from",
                    opacity: 1.0,
                  },
                  arrows: "to",
                };
                links.push(link);
              }
            }
          }
          this.nodes = [...influencers, ...mencionesNoInfluencers];
          this.edges = links;
        } else {
          this.nodes = [];
          this.nodeList = [];
        }
      },
    },
  },
  methods: {
    /* createEdges(e) {
      this.edges = [];
      let influencers = e.filter((x) => x.type == "influencer");
      influencers.map((x) => {
        let mentions = e.filter((m) => m.inf_key == x.inf_key);

        mentions.map((i) => {
          //console.log('listado.', this.iscColors[i.sentiment])
          if (x.id !== i.id) {
            let ed = {
              from: x.id,
              to: i.id,
              width: 2,
              color: {
                color: this.iscColors[i.sentiment],
                highlight: this.iscColors[i.sentiment],
                hover: this.iscColors[i.sentiment],
                inherit: "from",
                opacity: 1.0,
              },
              arrows: "to",
            };
            this.edges.push(ed);
          }
        });
      });
    }, */
    closeModal() {
      this.modal = {};
      this.$refs.network.unselectAll();
    },
    networkEvent() {
      let nodeIdSelected = this.$refs.network.getSelectedNodes();
      let postion_ = this.$refs.network.getPositions(nodeIdSelected);
      this.modal = this.nodes.find((x) => x.id === nodeIdSelected[0]);
      let x = postion_[nodeIdSelected].x;
      let y = postion_[nodeIdSelected].y;
      this.modal.x = x < 0 ? (x = 0) : (x = x > 640 ? (x = 640) : (x = x));
      this.modal.y = y < 0 ? (y = 0) : (y = y > 300 ? (x = 267) : (y = y));
      /*console.log('--->', nodeIdSelected)

      console.log('--->', this.influencers)

      /*console.log('dddddd', vis)*/
      /*if (this.networkEvents.length > 500) this.networkEvents = '';
      this.networkEvents += `${eventName}, `;*/
    },
  },
};
</script>

<style>
.zIndex_1 {
  z-index: 1;
}
.ico_left {
  width: 40px;
}
.close {
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.is_rt {
  border-left: 2.5px solid #00b1f2;
  line-height: 10px;
}
.ment_ {
  max-height: 288px;
  overflow-y: auto;
}
</style>
