<template>
   <v-tooltip bottom max-width="300" content-class="font12pt bgColorPrincipal text-center pt-4 pb-4">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            size="13"
            color="#a2acc4"
          >
            fas fa-info-circle
          </v-icon>
        </template>
        <span class="font-weight-bold">{{ tooltipText }}</span>
      </v-tooltip>
</template>

<script>
export default {
    name:'tooltipDefault',
    props: ['tooltipText'],
    data(){
        return{

        }
    }
}
</script>

<style>
</style>