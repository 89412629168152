<template>
  <v-main class="">
    <v-row>
      <div class="
          col
          mb-5
          d-flex
          align-center
        ">
        <div class="mr-auto">
          <h1 class="font18pt titleColor font-weight-bold" style="max-width: 120px;">
            {{ contextName }}
          </h1>
          <!-- <div
            class="
              btncontext
              white
              d-flex
              justify-center
              align-center
              ml-3
              rounded
            "
          >
            <v-icon size="15" color="#2c3852" class="pl-1">far fa-edit</v-icon>
          </div> -->
        </div>
        <div class="d-flex align-center multi-select justify-end">
          <div class="d-flex">
            <span class="font12pt parColor">
              Filter by:
            </span>
          </div>
          <div class="d-flex">
            <div class="margin-select mx-3">
              <vue-multi-select 
                v-model="socialNetworkFilter.values" 
                :select-options="socialNetworkFilter.data"
                :options="socialNetworkFilter.options" 
                :btnLabel="socialNetworkFilter.btnLabel"
                position="bottom-right"
                @close="updateActiveGroup()"
                :disabled="socialNetworkFilter.disabled">
                <template v-slot:option="{ option }">
                  <div class="d-flex align-center pl-2" @click="getSocialNetworkFilterOption(option)">
                    <input type="checkbox" class="mr-2" :checked="option.selected" />
                    <span>{{ option.name }}</span>
                  </div>
                </template>
              </vue-multi-select>
            </div>
            <div class="multiselectContainer" v-if="driverFilter.data.length">
              <vue-multi-select v-model="driverFilter.values" :select-options="driverFilter.data"
                :options="driverFilter.options" :btnLabel="driverFilter.btnLabel" :filters="driverFilter.filters"
                position="bottom-right" emptyTabText="No drivers" @close="closeDriverFilter()">
                <template v-slot:option="{ option }">
                  <div style="font: 1em 'Oxygen' !important; font-weight: 600">
                    <input type="checkbox" :checked="option.selected" />
                    {{ option.mask || option.term || (`${option.newspaper_mask ? option.page_url : ''}`) }}

                  </div>
                </template>
              </vue-multi-select>
            </div>
          </div>
        </div>
        <!-- <div class="d-flex align-center">
            <v-btn color="primary" @click="modalOpen = true">
              Create boards
            </v-btn>
        </div> -->
      </div>
      <ContextModal v-if="modalOpen" @close="handleCreateModalClose" />
    </v-row>
  </v-main>
</template>


<script>
import Vue from "vue";
import vueMultiSelect from "vue-multi-select";
import ContextModal from "@/components/context/CreateContextClustering.vue";
import "vue-multi-select/dist/lib/vue-multi-select.css";
export default {
  name: "Barcontext",
  props: ["contextName", "contextKeywords"],
  components: {
    ContextModal,
    vueMultiSelect,
  },
  data() {
    return {
      btnLabelValue: "Social Network...",
      socialNetworkFilter: {
        disabled: false,
        btnLabel: (value) => this.btnLabelValue,
        values: [],
        data: [],
        options: {
          multi: true,
          cssSelected: (option) =>
            option.selected
              ? { "background-color": "#00A5FF", color: "#ffffff !important" }
              : "",
        },
      },
      driverFilter: {
        btnLabel: (values) => `Drivers selected (${values.length})`,
        values: this.contextKeywords.length
          ? this.contextKeywords.filter((keyword) => keyword.active == true)
          : [],
        data: [],
        filters: [
          {
            nameAll: "Select All",
            nameNotAll: "Unselect All",
            func() {
              return true;
            },
          },
        ],
        options: {
          multi: true,
          groups: true,
          labelList: "elements",
          groupName: "title",
          cssSelected: (option) =>
            option.selected
              ? { "background-color": "#00A5FF", color: "#ffffff !important" }
              : "",
        },
      },
      newspapers: [],
      modalOpen: false,
      refetch: new Vue(),
      updateFilter: false,
      oldSocialNetworkValues: []
    };
  },
  async created() {
    const { data: { getNewspapers } } = await this.$store.dispatch('overview/getNewspapers')
    this.newspapers = getNewspapers
    this.driverFilter.data = this.groupDrivers();
    this.modifySocialNetworkFilter();


  },
  methods: {
    getSocialNetworkFilterOption(option) {
      this.updateFilter = true

      if(option.name === 'All') { 
        if(option.selected) this.socialNetworkFilter.values = [{ name: 'All' }]
        else {

          this.socialNetworkFilter.values = []

          this.socialNetworkFilter.data.map(item => {
            if(item.name != 'All') this.socialNetworkFilter.values.push({ name: item.name })
          })
        }
      } else {
        const indexAll = this.socialNetworkFilter.values.findIndex(i => i.name === 'All')
        const data = this.socialNetworkFilter.data.filter(i => i.name != 'All')

        if(option.selected && indexAll != -1) {
          this.socialNetworkFilter.values.splice(indexAll, 1)
        }

        let values = [...this.socialNetworkFilter.values]
        if(option.selected) {
          const currentIndex = this.socialNetworkFilter.values.findIndex(i => i.name === option.name)
          values.splice(currentIndex, 1)
        } else values.push({ name: option.name })

        if(values.length === data.length ) { 
          if(indexAll == -1) this.socialNetworkFilter.values.push({ name: 'All' })
        }
      }


    },
    handleCreateModalClose: function (newContext) {
      this.modalOpen = false;
      if (newContext) {
        this.refetch.$emit("refetch");
        this.$router.push({ name: "dashboard" });
      }
    },
    emit(eventName, value) {
      return new Promise((resolve, reject) => {
        this.$emit(eventName, value);
        this.$nextTick(resolve);
      });
    },
    async closeDriverFilter() {
      const driversToActive = [];
      const driversToDeactive = [];
      const _initial = this.contextKeywords.length
        ? this.contextKeywords.filter((keyword) => keyword.active == true)
        : [];
      const _new = this.driverFilter.values;
      /* if (_initial.length) { */
      await this.emit("dataLoading", true);
      for (const newDriver of _new) {
        const isNewInInitial = _initial.find((d) => d.id == newDriver.id);
        if (isNewInInitial) {
          continue;
        } else {
          driversToActive.push(newDriver);
        }
      }
      for (const initialDriver of _initial) {
        const isInitialInNew = _new.find((d) => d.id == initialDriver.id);
        if (isInitialInNew) {
          continue;
        } else {
          driversToDeactive.push(initialDriver);
        }
      }
      if (driversToActive.length > 0) {
        for (const x of driversToActive) {
          await this.emiterChange(x, true);
        }
      }
      if (driversToDeactive.length > 0) {
        for (const x of driversToDeactive) {
          await this.emiterChange(x, false);
        }
      }
      if (
        !(
          JSON.stringify(driversToDeactive) ===
          JSON.stringify(driversToActive)
        )
      ) {
        await this.emit("closeMultiSelect", true);
      } else {
        await this.emit("dataLoading", false);
      }
      /* } */
    },
    async emiterChange(keyword, status) {
      const contextId = this.$route.params.context;
      const data = {
        context_id: contextId,
        keyword_id: keyword.id,
        type: keyword.type,
        active: status,
      };
      await this.$store.dispatch("overview/toogleActiveKeyword", data);
    },
    async updateActiveGroup(keyword) {
      if(!this.updateFilter) return;

      const listSocialNetworks = [
        { key: 'fb', title: 'Facebook' },
        { key: 'tw', title: 'Twitter' },
        { key: 'ig', title: 'Instagram' },
        { key: 'lk', title: 'Linkedin' },
      ]

      const listNewspapers = this.newspapers.map((item) => {
        return {
          key: item.newspaper_id,
          title: item.newspaper_name
        }
      })

      const networks = []
      const newspapers = []

      if (this.socialNetworkFilter.values) {
        await this.emit("dataLoading", true);

        const contextId = this.$route.params.context;

        this.socialNetworkFilter.values.forEach(item => {

          if(listSocialNetworks.some(t => t.title == item.name)) {
            const n = listSocialNetworks.find(t => t.title == item.name);
            networks.push(n.key)
          } else if(listNewspapers.some(t => this.capitalize(t.title) == item.name)) {

            const n = listNewspapers.find(t => this.capitalize(t.title) == item.name);
  
            newspapers.push(n.key)
          }
        })

        const data = {
          context_id: contextId,
          active_social_networks: networks, 
          newspaper_ids: newspapers
        };

        await this.$store.dispatch('overview/filterSocialNetworks', data)

        this.socialNetworkFilter.values = [];
        await this.emit("close");
      }
      this.updateFilter = false
        // this.$emit('changeSocialFilter', [change]);
        await this.emit("close");
    },
    disableGroups() {
      const tw_drivers = this.contextKeywords.filter(
        (keyword) => keyword.type == "tw"
      ).length;
      const fb_drivers = this.contextKeywords.filter(
        (keyword) => keyword.type == "fb"
      ).length;
      const ig_drivers = this.contextKeywords.filter(
        (keyword) => keyword.type == "ig"
      ).length;
      const lk_drivers = this.contextKeywords.filter(
        (keyword) => keyword.type == "lk"
      ).length;
      const nws_drivers = this.contextKeywords.filter(
        (keyword) => keyword.type == "nws"
      ).length;
      return tw_drivers > 0 && fb_drivers > 0 && ig_drivers > 0 && lk_drivers > 0 && nws_drivers > 0 ? false : true;
    },
    groupDrivers() {
      const data = [];
      const types = [
        { key: 'fb', title: 'FACEBOOK' },
        { key: 'tw', title: 'TWITTER' },
        { key: 'ig', title: 'INSTAGRAM' },
        { key: 'lk', title: 'LINKEDIN' },
      ]

      this.newspapers.map((item) => {
        types.push({
          key: item.newspaper_id,
          title: item.newspaper_name
        })
      })

      if (this.contextKeywords.length) {
        types.forEach(type => {
          if (this.contextKeywords.filter((keyword) => keyword.type == type.key).length) {
            data.push({
              title: type.title,
              elements: this.contextKeywords.filter(
                (keyword) => keyword.type == type.key
              ).length
                ? this.contextKeywords.filter((keyword) => keyword.type == type.key)
                : [],
            });
          } else if(this.contextKeywords.filter((keyword) => keyword.newspaper_id == type.key).length) { // Si es newspaper
            data.push({
              title: this.capitalize(type.title),
              elements: this.contextKeywords.filter(
                (keyword) => keyword.newspaper_id == type.key
              ).length
                ? this.contextKeywords.filter((keyword) => keyword.newspaper_id == type.key)
                : [],
            });
          }
        });
        
      }
      return data;
    },
    modifySocialNetworkFilter() {
      const groupsCount = this.driverFilter.data.length;
      for (let i = 0; i < groupsCount; i++) {
        this.socialNetworkFilter.data.push({ name: this.capitalize(this.driverFilter.data[i].title) });
      }
      if (groupsCount == 1) {
        this.socialNetworkFilter.disabled = true;
      } else {
        this.socialNetworkFilter.data.unshift({ name: "All" });
      }
    },
    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  },
  watch: {
    contextKeywords: {
      immediate: true,
      //deep: true,
      handler() {
        this.driverFilter.values = this.contextKeywords.length
          ? this.contextKeywords.filter((keyword) => keyword.active == true)
          : [];
      },
    },
    "driverFilter.values": {
      deep: true,
      handler() {
        const values = this.driverFilter.values;

        this.socialNetworkFilter.values = []

        const isTwitter = (item) => item.type == "tw";
        const isFacebook = (item) => item.type == "fb";
        const isInstagram = (item) => item.type == "ig";
        const isLinkedin = (item) => item.type == "lk";

        this.newspapers.map((nws) => {
          if(values.some((item) => item.newspaper_id === nws.newspaper_id)) { 
            this.socialNetworkFilter.values.push({ name: this.capitalize(nws.newspaper_name) })
          }
        })
        const isNewspapers = (item) => item.type == "nws";

        const containsTW = values.some(isTwitter);
        const containsFB = values.some(isFacebook);
        const containsIG = values.some(isInstagram);
        const containsLK = values.some(isLinkedin);
        const containsNWS = values.some(isNewspapers);
        const onlyTW = values.every(isTwitter);
        const onlyFB = values.every(isFacebook);
        const onlyIG = values.every(isInstagram);
        const onlyLK = values.every(isLinkedin);
        const onlyNWS = values.every(isNewspapers);

        if(containsTW) this.socialNetworkFilter.values.push({ name: 'Twitter' })
        if(containsFB) this.socialNetworkFilter.values.push({ name: 'Facebook' })
        if(containsIG) this.socialNetworkFilter.values.push({ name: 'Instagram' })
        if(containsLK) this.socialNetworkFilter.values.push({ name: 'Linkedin' })

        if(this.socialNetworkFilter.values.length === this.socialNetworkFilter.data.filter(i=>i.name != 'All').length ) {
          this.socialNetworkFilter.values.push({ name: 'All' })
        }

        if (containsTW && containsFB && containsIG && containsLK && containsNWS) {
          this.btnLabelValue = "All";
        } else {
          if (onlyTW) {
            this.btnLabelValue = "Twitter";
          } else {
            if (onlyFB) {
              this.btnLabelValue = "Facebook";
            } else {
              if (onlyIG) {
                this.btnLabelValue = "Instagram";
              } else {
                if (onlyLK) {
                  this.btnLabelValue = "Linkedin";
                } else {
                  if (onlyNWS) {
                    this.btnLabelValue = "Newspapers";
                  } else {
                    this.btnLabelValue = "Social Network...";
                  }
                }
              }
            }
          }
        }
      },
    },
  },
};
</script>

<style>
.btncontext {
  height: 35px;
  width: 35px;
  box-shadow: 0 2px 3.9px 0.1px rgb(0 0 0 / 15%);
}

.multi-select {
  margin-left: 277px;
}

.margin-select {
  margin-right: 16px;
}

.tab {
  justify-content: center;
}

.tab-item {
  text-align: center;
  padding: 0% !important;
  flex: 1 1 auto;
}

.tab-item span {
  width: 100%;
  font-weight: bold;
}

.helperButton {
  padding: 0 5px 0 5px;
  border-radius: 5px !important;
}

.tab-item.active span {
  border-bottom-color: #00a5ff !important;
  color: #00a5ff !important;
}

.margin-select .helperContainer {
  padding: 0px !important;
}

.checkboxLayer {
  z-index: 4 !important;
}

.checkBoxContainer {
  min-height: 0px !important;
}

.select>button:disabled {
  color: #d1d1d1;
}

.select>button:disabled>.buttonLabel>.caret {
  border-top: 4px solid #d1d1d1;
}
</style>
