<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex align-center mt-5">
        <h1 class="font15pt font-weight-bold mr-2">Text Analytics Detail</h1>
        <tooltipComponent :tooltipText="tooltip" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col md="4" class="pl-0">
        <ClusteringTable :dataTable="words" />
        <div class="text-right pr-1">
          <span class="font10pt leyendColor">{{ words.itemsTable.length }} total words</span>
        </div>
      </v-col>
      <v-col md="4" class="pl-0 pr-0">
        <ClusteringTable :dataTable="hashtag" />
        <div class="text-right pr-1">
          <span class="font10pt leyendColor">{{ hashtag.itemsTable.length }} total hashtag</span>
        </div>
      </v-col>
      <v-col md="4" class="pr-0">
        <ClusteringTable :dataTable="mentions" />
        <div class="text-right pr-1">
          <span class="font10pt leyendColor">{{ mentions.itemsTable.length }} total mentions</span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import tooltipComponent from "@/components/base/tooltip.vue";
import ClusteringTable from "./clusteringTable.vue";
import { util } from "@isc/styleguide";

export default {
  name: "TextAnalyticsDetail",
  components: { tooltipComponent, ClusteringTable },
  props: {
    wordsKeySamples: Array,
    hashtagsSamples: Array,
    mentionsSamples: Array,
  },
  data() {
    return {
      tooltip:
        "Detail of the number of words that were generated from the sample of drivers",
      words: {
        text: "Top Words",
        itemsTable: [],
      },
      hashtag: {
        text: "Top Hashtag",
        itemsTable: [],
      },
      mentions: {
        text: "Top Mentions",
        itemsTable: [],
      },
    };
  },
  watch: {
    wordsKeySamples: {
      immediate: true,
      handler() {
        // MENTIONS
        let wordsList = [];

        this.wordsKeySamples.map((item) => {
          const sentiments = item["agg_terms_sample_sentiment.keyword"].buckets;
          const maxSentiment = util.maxFromObjectArray(sentiments, "doc_count");

          wordsList.push({
            value: item.key,
            sentiment: maxSentiment.key,
            count: item.doc_count,
          });
        });
        if (wordsList.length > 10) {
          wordsList = wordsList.slice(0, 10);
        }
        this.words.itemsTable = wordsList;
      },
    },
    hashtagsSamples: {
      immediate: true,
      handler() {
        // HASHTAGS
        const hashtagsList = [];

        this.hashtagsSamples.map((item) => {
          const sentiments = item["agg_terms_sample_sentiment.keyword"].buckets;
          const maxSentiment = util.maxFromObjectArray(sentiments, "doc_count");

          hashtagsList.push({
            value: `#${item.key}`,
            sentiment: maxSentiment.key,
            count: item.doc_count,
          });
        });

        this.hashtag.itemsTable = hashtagsList;
      },
    },
    mentionsSamples: {
      immediate: true,
      handler() {
        // MENTIONS
        const mentionsList = [];

        this.mentionsSamples.map((item) => {
          const sentiments = item["agg_terms_sample_sentiment.keyword"].buckets;
          const maxSentiment = util.maxFromObjectArray(sentiments, "doc_count");

          mentionsList.push({
            value: item.key,
            sentiment: maxSentiment.key,
            count: item.doc_count,
          });
        });

        this.mentions.itemsTable = mentionsList;
      },
    },
  },
};
</script>

<style></style>
