import Router from "vue-router";
import ClusteringAnalysis from "@/views/ClusteringAnalysis.vue";

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/clustering-analysis/:context",
      name: "Clustering Analysis",
      component: ClusteringAnalysis,
    },
    {
      path: "/clustering-analysis",
      redirect: "/dashboard/your-boards",
    },
  ],
});
