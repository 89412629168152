<template>
  <div id="clustering-analysis">
    <v-app>
      <v-main class="bgColorBase wrap-app" >
        <router-view @setLoader="setLoader"/>
      </v-main>
      <!-- <v-overlay :value="overlay" >
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay> -->
      <Loader v-if="loading"/>
    </v-app>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Loader from "./components/base/loader.vue";

export default {
  data() {
    return {
      loading: true,
    };
  },
  components:{
    Loader
  },
  created() {
    this.addTitle("Clustering Analysis");
    this.disabledMenuItem(false);
    this.$store.dispatch("global/setDatePickerActive", true);
    this.loadNetworks()
  },
  methods: {
    ...mapActions("global", ["addTitle", "disabledMenuItem", "loadNetworks"]),
    setLoader(val){
      this.loading = val;
    }
  },
};
</script>
<style></style>
